/*Pagination*/

.pagination {
  a, span {
    position: relative;
    display: block;
    padding: $pagination-padding-y $pagination-padding-x;
    margin-left: -$pagination-border-width;
    line-height: $pagination-line-height;
    color: $pagination-color;
    background-color: $pagination-bg;
    border: $pagination-border-width solid $pagination-border-color;

    &:hover {
      color: $pagination-hover-color;
      text-decoration: none;
      background-color: $pagination-hover-bg;
      border-color: $pagination-hover-border-color;
    }

    &:focus {
      z-index: 2;
      outline: 0;
      box-shadow: $pagination-focus-box-shadow;
    }

    // Opinionated: add "hand" cursor to non-disabled .page-link elements
    &:not([disabled]):not(.disabled) {
      cursor: pointer;
    }
  }

  .disabled {
    a, span {
      background-color: $pagination-hover-bg;
    }
  }

  li {
    &:first-child {
      > a, > span {
        margin-left: 0;
        @include border-left-radius($border-radius);
      }
    }

    &:last-child {
      > a, > span {
        @include border-right-radius($border-radius);
      }
    }

    &.active a {
      z-index: 1;
      color: $pagination-active-color;
      background-color: $pagination-active-bg;
      border-color: $pagination-active-border-color;
    }

    &.disabled a {
      color: $pagination-disabled-color;
      pointer-events: none;
      // Opinionated: remove the "hand" cursor set previously for .page-link
      cursor: auto;
      background-color: $pagination-disabled-bg;
      border-color: $pagination-disabled-border-color;
    }
  }
}

.btn-secondary.disabled {
  color: #495057 !important;
}