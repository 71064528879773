.summary {
  vertical-align: middle;
  margin-right: 10px;
}

.messages {
  tr {
    &.readed {
      background-color: $gray-100;
    }
    &.unread td {
      font-weight: 600;
      color: $gray-800
    }
    &.important {
      .category {
        color: $red;
      }
    }
  }
  td {
    padding-right: 4px;
    vertical-align: middle;
    &.message-icon {
      color: $gray-500;
      font-size: 1.2rem;
      padding: 4px;
    }
    .pretty {
      margin-right: 0;
    }
  }
  col.bulk-actions {
    width: 40px;
  }

  .sender {
    width: 25%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}


