.rounded800 {
  @include border-radius($border-radius-lg);
}

.wrap {
  padding-top: 6rem;
}

.bg-red {
  @include gradient-directional($danger, darken(adjust_hue($danger, 1050), 00), 45deg);
  @extend %white-font;
}

.bg-tealy {
  @include gradient-directional($color-1, $color-1b, 45deg);
  @extend %white-font;
}

.bg-orange {
  @include gradient-directional($warning, darken(adjust_hue($warning, 230), 20), 45deg);
  @extend %white-font;
}

.bg-green {
  @include gradient-directional($success, darken(adjust_hue($success, -20), 00), 45deg);
  @extend %white-font;
}

%white-font {

  > * {
    color: white;
  }

  .box-title small {
    color: $gray-800;
  }
}

.login-logo, .register-logo {
  font-size: 35px;
  text-align: center;
  margin-bottom: 25px;
  font-weight: 300;
}


footer {
  padding: $grid-gutter-width 10px;
  text-transform: uppercase;
  font-size: .70rem;
  text-align: center;
  color: $gray-600;

  a {
    color: $gray-800;
  }
}

input[type="checkbox"].action800 {
  display: none;
}

input[type="checkbox"].hidden800 {
  opacity: 0;
  width: 0px;
  height: 0px;
}

.input-group {
  .input-group-addon {
    border-radius: 0 2rem 2rem 0;
    border: 1px solid #ced4da;
    border-left: none;
    width: 30px;
    text-align: center;
    font-size: 18px;
    color: $gray-800;
    font-weight: 700;
    padding-top: 1px;
  }
}

.pretty {
  margin-right: 0 !important;
}

// Override tooltip font-size

.tooltip {
  font-size: .70rem;
}

.main-title {
  text-align: center;
  margin-bottom: 2rem;

  h1, h2, h3 {
    font-weight: 300;
    color: $gray-700;
  }

  p {
    color: $gray-600;
  }
}

.list-group-item {
  a {
    .item-img {
      float: left;
      font-size: 2em;
      max-width: 60px;
    }

    .item-info {
      margin-left: 60px;
    }

    &:hover {
      text-decoration: none;
    }
  }
}

.box-header {
  > .glyphicon, > [class *= ion], > [class *= fa-], .box-title {
    display: inline-block;
    font-size: 18px;
    margin: 0;
    line-height: 1;
  }
}

@each $side in $sides {
  .margin-#{$side} {
    margin-#{$side}: 5px;
  }
  .padding-#{$side} {
    padding-#{$side}: 5px;
  }
  .#{$side} {
    position: relative;
    #{$side}: 5px;
  }
}

@each $color in $colors {

  //  *** TEXT COLORS
  .text-#{nth($color,1)} {
    color: #{nth($color,2)} !important;
  }
  // *** FLAT BACKGROUNDS
  .bg-#{nth($color,1)}:not(td):not(tr) {
    background-color: #{nth($color,2)} !important;
    //    Exceptions
    @if (nth($color,1) =='gray') {
      color: #e6e5e5;
    } @else if (nth($color,1) =='white') {
      color: $gray-600
    } @else {
      //@extend %white-font;
    }

  }
  // *** BORDER ONLY
  .border-#{nth($color,1)} {
    border: 1px solid #{nth($color,2)} !important;
    //color: #{nth($color,2)} !important;
    background-color: transparent !important;
  }
  .label.border-#{nth($color,1)} {
    color: #{nth($color,2)}
  }
}
ul{
  list-style: none;
  padding: 0px;
}




