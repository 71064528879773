.form-control {
  border-radius: 2rem;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none;
}

.has-feedback {
  position: relative;
}

.dropdown-menu {
  > li {
    > a {
      padding: 10px 20px;
      display: block;
      clear: both;
      font-weight: normal;
      line-height: 1.5;
      white-space: nowrap;
    }
  }
}

.dropdown-menu:not(.datepicker) {
  animation: ddAppear .4s;
}

@keyframes ddAppear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*Fade*/

.fade.in {
  opacity: 1;
}
