/* Widget: TODO LIST */
.todo-list {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: auto;

  > li {
    border-radius: 2px;
    padding: 10px;
    //background: #e0e0e0;
    margin-bottom: 2px;
    border-bottom: 1px solid #e0e0e0;
    color: #444;

    > input[type='checkbox'] {
      margin: 0 10px 0 5px;
    }

    .text {
      display: inline-block;
      margin-left: 5px;
      font-weight: 600;
    }

    .label {
      margin-left: 10px;
      font-size: 9px;
    }

    .tools {
      display: none;
      float: right;
      color: #dd4b39;

      > .fa, > .glyphicon, > .ion, [class *= ion] {
        margin-right: 5px;
        cursor: pointer;
      }
    }

    :hover .tools {
      display: inline-block;
    }
  }

  > li.done {
    color: #999;

    .text {
      text-decoration: line-through;
      font-weight: 500;

      .label {
        background: #d2d6de !important;
      }
    }
  }

  > li:last-of-type {
    border-bottom: none;
  }

  .danger {
    border-left-color: #dd4b39;
  }

  .warning {
    border-left-color: #f39c12;
  }

  .info {
    border-left-color: #00c0ef;
  }

  .success {
    border-left-color: #00a65a;
  }

  .primary {
    border-left-color: #3c8dbc;
  }

  .handle {
    display: inline-block;
    cursor: move;
    margin: 0 5px;
  }

}

.fc-color-picker {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    float: left;
    font-size: 30px;
    margin-right: 5px;
    line-height: 30px;

    i {

      display: inline-block;
      -webkit-transition: -webkit-transform linear 0.3s;
      -moz-transition: -moz-transform linear 0.3s;
      -o-transition: -o-transform linear 0.3s;
      transition: transform linear 0.3s;

      &:hover {
        -webkit-transform: rotate(30deg);
        -ms-transform: rotate(30deg);
        -o-transform: rotate(30deg);
        transform: rotate(30deg);
      }
    }
  }
}


.fc-content {
  color: white;
}


/* Widget: GRIDVIEW */
a.asc:after, a.desc:after {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  padding-left: 5px;
}

a.asc:after {
  content: "\f15d";
}

a.desc:after {
  content: "\f15e";
}

.sort-numerical a.asc:after {
  content: "\f163";
}

.sort-numerical a.desc:after {
  content: "\f162";
}

.sort-ordinal a.asc:after {
  content: "\f163";
}

.sort-ordinal a.desc:after {
  content: "\f162";
}

/* Widget: SELECT2 */
.select2-container--krajee-bs4 {
  .select2-selection {
    border-radius: 1rem !important;
    min-height: 2rem !important;

    .select2-search__field {
      height: 2rem !important;
    }
  }
  .select2-selection--multiple .select2-selection__rendered{
    padding-bottom: 3px !important;
  }
}

/* Widget: FANCYBOX */
.fancybox-inner {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}


/* Widget: DATETIMEPICKER */

.kv-datetime-picker {
  border-radius: 3rem 0 0 3rem;
  padding: 6px 12px;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
}