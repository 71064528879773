.note-editor {

  .note-editable {
    padding: $grid-gutter-width !important;
    background-color: $gray-100 !important;
  }
  .card-header {
    background-color: white;
    &.note-toolbar {
      padding: $grid-gutter-width / 4;
      text-align: center;
    }
  }

  &.note-frame {
    border:none !important;

    .note-statusbar {
      background-color: red;
      @include border-radius($border-radius-lg !important);
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
      .note-resizebar {
        height: 24px !important;
      }
    }

  }
}