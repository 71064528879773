.jumbotron {
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight: 100;
  }
  &.bg-image {
    position: relative;
    background-size: cover;
    background-position: center;
    hr {
      border-top:1px solid $gray-700;
    }
    > * {
      z-index: 2;
      position: relative;
    }
    .btn-group {
      > * {
        background-color: rgba(black,.4);
      }
    }
    &:before {
      content:"";
      top:0;
      left:0;
      bottom:0;
      right:0;
      position: absolute;
      background-color: rgba(black,.7);
      @include border-radius($border-radius-lg);
    }
  }
  &.bg-image-dark {

  }
}