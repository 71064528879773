.sport {
  .sport-image {
    height: 100%;
    align-self: center;
    display: flex;
    justify-content: center;
    img {
      max-width: 160px;
      padding: 1rem;
    }
  }
  .sport-details {
    padding: $grid-gutter-width;
    padding-bottom: $grid-gutter-width / 4;
  }
  .short-description {
    color: $gray-600;
  }
  .date {
    margin: 0;
    margin-bottom: .4rem;
    i {
      margin-right: .3rem;
    }
  }
  .sport-note {
    color: $gray-500;
  }

  .carousel {
    &:hover {
      .carousel-control-prev,.carousel-control-next {
        display: flex;
      }
    }
  }
  .carousel-control-prev,.carousel-control-next {
    display: none;
  }
}