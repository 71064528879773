%box-title {
  font-size: 1.2rem;
  color: $gray-800;
  margin: 0;
  small {
    color: $gray-600;
    font-size: .8rem;

  }
}

.box {
  background-color: white;
  margin-bottom: $grid-gutter-width;
  overflow: hidden;
  @include border-radius($border-radius-lg);
  @include box-shadow(0px 2px 4px 0 rgba(black, .1));
  display: flex;
  flex-direction: column;

  .minimized {
    transition: all .3s ease;
    max-height: 0;

  }
  input[type="checkbox"] {
    &:not(:checked) {
      ~ .minimized {
        padding-top: 0 !important;
        padding-bottom: 0 !important;

      }
    }
    &:checked {
      ~ .minimized {
        max-height: 1000px;
      }

      ~ div .profile-name label {
        transform: rotateZ(45deg);
      }
    }
  }

  .box-header {
    padding: $grid-gutter-width / 1.5 $grid-gutter-width $grid-gutter-width / 4 ;
    .box-title {
      @extend %box-title;
    }
  }
  .box-header + .box-body {
    padding-top: $grid-gutter-width / 4;
  }
  .box-body {
    color: $gray-600;
    padding: $grid-gutter-width;
    padding-bottom: $grid-gutter-width / 2;
    @include media-breakpoint-only(xs) {
      padding: $grid-gutter-width / 4 $grid-gutter-width / 2;
    }
  }
  .box-footer {
    padding: $grid-gutter-width / 4 $grid-gutter-width $grid-gutter-width / 1.5;
    @include media-breakpoint-only(xs) {
      padding: $grid-gutter-width / 2 $grid-gutter-width / 2;
    }
  }

  > .list-group {
    .list-group-item {
      border-radius: 0 !important;
      border-right: 0;
      border-left: 0;
      padding-left: $grid-gutter-width;
      color: $gray-600;
      &:first-child {
        border-top: none;
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.btn-box-tool {
  color: $gray-600;
}

/* info-boxes */
.info-box {
  margin-bottom: $grid-gutter-width / 2;
  background-color: white;
  @include border-radius($border-radius-lg);
  @include box-shadow(0px 2px 4px 0 rgba(black, .1));

  overflow: hidden;
  display: flex;
  flex-direction: row;
  .info-icon {
    align-self: center;
    padding: $grid-gutter-width / 4 $grid-gutter-width / 1.5;
  }
  .info-text {
    align-self: center;
  }
  .info-title {
    font-size: .8rem;
    margin: 0;
  }
  .info-description {
    text-overflow: ellipsis;
    font-size: .7rem;
  }
  .info-icon {
    font-size: 2.2rem;
  }
}


