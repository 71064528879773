/* 800 theme variables*/

/* Colors pairs */

$color-1: #00cbcd;
$color-1b: #048bb1;

/* Bootstrap overrided variables*/

/* Bootstrap colors modified */

$blue: #00b4d9;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #00a65a;
$teal: #39cccc;
$cyan: #17a2b8;
$lime: #01ff70;
$aqua: #00c0ef;
$black: #111111;
$light-blue: #3c8dbc;
$navy: #001f3f;
$olive: #3d9970;
$fuchsia: #f012be;
$maroon: #d81b60;
$gray-800: #343a40;
$gray-600: #808080;
$white: #fff;
$colors: ();
$colors: map-merge((
        "blue": $blue,
        "indigo": $indigo,
        "purple": $purple,
        "pink": $pink,
        "red": $red,
        "orange": $orange,
        "yellow": $yellow,
        "green": $green,
        "teal": $teal,
        "cyan": $cyan,
        "white": $white,
        "gray": $gray-800,
        "gray-dark": $gray-600,
        "lime":$lime,
        "aqua":$aqua,
        "black":$black,
        "light-blue":$light-blue,
        "navy":$navy,
        "olive":$olive,
        "fuchsia":$fuchsia,
        "maroon":$maroon,
), $colors);
$primary: $blue;
$secondary: $gray-600;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-400;
$dark: $gray-800;

$theme-colors: ();
$theme-colors: map-merge((
        "primary": $primary,
        "secondary": $secondary,
        "success": $success,
        "info": $info,
        "warning": $warning,
        "danger": $danger,
        "light": $light,
        "dark": $dark
), $theme-colors);
$link-color: theme-color("primary");
$link-decoration: none;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: underline;


$grid-gutter-width:           24px;



$font-size-base: .80rem;
// Assumes the browser default, typically `16px`
$font-size-lg:                ($font-size-base * 1.25) ;
$font-size-sm:                ($font-size-base * .875) ;

$border-radius: 3rem;
$border-radius-lg: .9rem;
$border-radius-sm: .9rem;


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y:         .375rem;
$input-btn-padding-x:         .75rem;
$input-btn-line-height:       $line-height-base;

$input-btn-focus-width:       .2rem;
$input-btn-focus-color:       rgba(theme-color("primary"), .25);
$input-btn-focus-box-shadow:  0 0 0 $input-btn-focus-width $input-btn-focus-color;

$input-btn-padding-y-sm:      .05rem;
$input-btn-padding-x-sm:      .5rem;
$input-btn-line-height-sm:    $line-height-sm;

$input-btn-padding-y-lg:      .5rem;
$input-btn-padding-x-lg:      1rem;
$input-btn-line-height-lg:    $line-height-lg;

$input-btn-border-width:      $border-width;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y:               $input-btn-padding-y;
$btn-padding-x:               $input-btn-padding-x;
$btn-line-height:             $input-btn-line-height;

$btn-padding-y-sm:            $input-btn-padding-y-sm;
$btn-padding-x-sm:            $input-btn-padding-x-sm;
$btn-line-height-sm:          $input-btn-line-height-sm;

$btn-padding-y-lg:            $input-btn-padding-y-lg;
$btn-padding-x-lg:            $input-btn-padding-x-lg;
$btn-line-height-lg:          $input-btn-line-height-lg;

$btn-border-width:            $input-btn-border-width;

$btn-font-weight:             $font-weight-normal;
$btn-box-shadow:              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075);
$btn-focus-width:             $input-btn-focus-width;
$btn-focus-box-shadow:        $input-btn-focus-box-shadow;
$btn-disabled-opacity:        .65;
$btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125);

$btn-link-disabled-color:     $gray-600;

$btn-block-spacing-y:         .5rem;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           $border-radius;
$btn-border-radius-lg:        2rem;
$btn-border-radius-sm:        $border-radius-sm;

$badge-border-radius: .1rem;
$badge-pill-border-radius: .2rem;
$badge-font-weight: 400;
$badge-padding-y: .4em;

$body-bg: #ededed;
$body-color:                $gray-700;


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 830px,
        xl: 832px
);

$font-family-sans-serif: 'Open Sans', sans-serif;
$font-family-base: $font-family-sans-serif;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: true;
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: true;
$enable-transitions: true;
$enable-hover-media-query: false;
$enable-grid-classes: true;
$enable-print-styles: true;

// Tooltips

//$tooltip-max-width:           200px;
//$tooltip-color:               $white;
$tooltip-bg: $gray-700;
//$tooltip-opacity:             .9;
//$tooltip-padding-y:           .25rem;
$tooltip-padding-x: .9rem;
//$tooltip-margin:              0;
//
//$tooltip-arrow-width:         .8rem;
//$tooltip-arrow-height:        .4rem;
$tooltip-arrow-color: $tooltip-bg;

// Pagination

$pagination-padding-y:              .5rem;
$pagination-padding-x:              .75rem;
$pagination-padding-y-sm:           .25rem;
$pagination-padding-x-sm:           .5rem;
$pagination-padding-y-lg:           .75rem;
$pagination-padding-x-lg:           1.5rem;
$pagination-line-height:            1.25;

$pagination-color:                  theme-color("secondary");
$pagination-bg:                     $white;
$pagination-border-width:           $border-width;
$pagination-border-color:           $gray-300;

$pagination-hover-color:            $link-hover-color;
$pagination-hover-bg:               $gray-200;
$pagination-hover-border-color:     $gray-300;

$pagination-active-color:           $white;
$pagination-active-bg:              theme-color("secondary");
$pagination-active-border-color:    theme-color("secondary");

$pagination-disabled-color:         $gray-600;
$pagination-disabled-bg:            $white;
$pagination-disabled-border-color:  $gray-300;



$input-border-radius:                   $border-radius;
$input-border-radius-lg:                $border-radius-lg;
$input-border-radius-sm:                $border-radius-sm;

// Jumbotron

$jumbotron-padding:                 2rem;
$jumbotron-bg:                      white;


$input-group-addon-bg:                  white;


// Cards

$card-spacer-y:                     .75rem;
$card-spacer-x:                     1.25rem;
$card-border-width:                 $border-width;
$card-border-radius:                $border-radius;
$card-border-color:                 rgba($black, .125);
$card-inner-border-radius:          calc(#{$card-border-radius} - #{$card-border-width});
$card-cap-bg:                       rgba($black, .03);
$card-bg:                           $white;

$card-img-overlay-padding:          1.25rem;

$card-group-margin:                 ($grid-gutter-width / 2);
$card-deck-margin:                  $card-group-margin;

$card-columns-count:                3;
$card-columns-gap:                  1.25rem;
$card-columns-margin:               $card-spacer-y;



/* More 800 theme variables. */

/* Video */

$video-progress-height: 3px;
$video-progress-color: $red;


/* MAIN NAVBAR*/
$nav800-height:58px;

/* SIDEBAR */
$sidebar-width:140px;
/* SIDEBAR */
$sides:( "top","left","bottom", "right");