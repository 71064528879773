/* Bootstrap 4 beta 3*/
@import "../bootstrap4/scss/functions";
@import "../bootstrap4/scss/variables";
/* Override custom variables */
@import "variables800";
@import "../bootstrap4/scss/mixins";
@import "../bootstrap4/scss/root";
@import "../bootstrap4/scss/reboot";
@import "../bootstrap4/scss/type";
@import "../bootstrap4/scss/images";
@import "../bootstrap4/scss/code";
@import "../bootstrap4/scss/grid";
@import "../bootstrap4/scss/tables";
@import "../bootstrap4/scss/forms";
@import "../bootstrap4/scss/buttons";
@import "../bootstrap4/scss/transitions";
@import "../bootstrap4/scss/dropdown";
@import "../bootstrap4/scss/button-group";
@import "../bootstrap4/scss/input-group";
@import "../bootstrap4/scss/custom-forms";
@import "../bootstrap4/scss/nav";
@import "../bootstrap4/scss/navbar";
@import "../bootstrap4/scss/card";
@import "../bootstrap4/scss/breadcrumb";
@import "../bootstrap4/scss/pagination";
@import "../bootstrap4/scss/badge";
@import "../bootstrap4/scss/jumbotron";
@import "../bootstrap4/scss/alert";
@import "../bootstrap4/scss/progress";
@import "../bootstrap4/scss/media";
@import "../bootstrap4/scss/list-group";
@import "../bootstrap4/scss/close";
@import "../bootstrap4/scss/modal";
@import "../bootstrap4/scss/tooltip";
@import "../bootstrap4/scss/popover";
@import "../bootstrap4/scss/carousel";
@import "../bootstrap4/scss/utilities";
@import "../bootstrap4/scss/print";
/* Custom */
@import "general";
@import "boxes";
@import "navbar800";
@import "profile";
@import "tabs";
@import "video";
@import "examples";
@import "email";
@import "summernote";
@import "product";
@import "tipography";
@import "jumbotron800";
@import "sidebar";
@import "sport";
@import "widgets";
@import "legacy";
@import "breadcrumbs";
@import "pagination";

