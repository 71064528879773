.grid-examples {
  [class*="col-"] {
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: rgba(59, 74, 124, 0.15);
    border:1px solid rgba(86, 61, 124, .1);
  }
  .row {
    margin-bottom: 1rem;
    .row {
      margin-top: 1rem;
      margin-bottom: 0;
    }
  }
}
//.demo-mail {
//  tbody {
//    tr:nth-child(-n+3) {
//      td {
//        font-weight: 600;
//        color: $gray-800
//      }
//    }
//  }
//}