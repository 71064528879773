.product {
  .product-details {
    padding: $grid-gutter-width;
    padding-bottom: $grid-gutter-width / 4;
  }
  .short-description {
    color: $gray-600;
  }
  .price {
    font-size: 1.4rem;
  }
  .product-note {
    color: $gray-500;
  }

  .carousel {
    &:hover {
      .carousel-control-prev,.carousel-control-next {
        display: flex;
      }
    }
  }
  .carousel-control-prev,.carousel-control-next {
    display: none;
  }
}