/* @todo: Dar la opcion de navbar fixed o floated*/
.navbar800 {
  min-height: $nav800-height;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: $zindex-fixed;
  background-color: rgba($body-bg, .9);
  background-color: white;
  .btn-link {
    color: $gray-700;
  }
  i {
    font-size: 1.80rem;
  }
  > div {
    align-self: center;
  }

  label.menu-opener:hover {
    cursor: pointer;
  }
  .menu-opener {
    padding-top: 6px;
    height: 100%;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-self: center;
    i {
      @include transition(all .3s ease);
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);

      &:first-child {
        top: -40px;
      }
      &:last-child {

      }
    }
  }

  .notifications {
    .dropdown-toggle {
      &:after {
        display: none;
      }
    }
  }
}

.menu800 {
  background-color: white;
  padding: $grid-gutter-width /2;
  position: absolute;
  left: 0;
  right: 0;
  transition: all .3s ease;
  z-index: -1;
  @include box-shadow(0px 2px 4px 0 rgba(black, .1));

  @extend %statusClosed;
  &:hover {
    @extend %statusOpened
  }

  .menu800-links {
    align-self: center;
  }
  &-group {
    margin-right: 0;
    margin-left: 0;
    > .col,
    > [class*="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
    &:hover {
      .menu800-icon {
        color: $color-1
      }
    }
  }
  &-icon {
    @include transition(all .3s ease);
    display: flex;
    align-self: center;
    text-align: center;
    color: $gray-600;
    i {
      font-size: 3rem;
    }

  }
  &-links {
    a {
      display: block;
      text-transform: uppercase;
      font-size: .85rem;
      color: $gray-600;
      border-bottom: 1px solid $gray-200;
      padding: 6px;
      white-space: nowrap;
      &:hover {
        color: $color-1;
        text-decoration: none;
      }
      i {
        padding-right: 5px;
        position: relative;
        top: 6px;
      }
      @include media-breakpoint-up(sm) {
        padding: 1px;
        font-size: .70rem;
        font-weight: 600;
        border: none;

      }

    }
  }
}

/* Pure css menu */
%statusOpened {
  top: 100%;
  opacity: 1;
}

%statusClosed {
  top: -100vh;
  max-height: 100vh;
  overflow-y: auto;
  opacity: .7;
  @include media-breakpoint-up(sm) {
    max-height: none;
    top: -300px;
  }

}

#navbar800toggle:checked,
.nav-on-hover #navbar800toggle:hover {
  & ~ .wrap {
    max-height: 80vh;
    overflow-y: hidden;
    @include media-breakpoint-up(sm){
      max-height: none;
      overflow-y: auto;
    }
    .navbar800 {
      .menu800 {
        @extend %statusOpened
      }
    }
  }
}

/* Change icon only when click is required*/

#navbar800toggle:checked,
{
  & ~ .wrap {
    .navbar800 {
      .menu-opener {
        i {
          &:first-child {
            top: 50%;
          }
          &:last-child {
            top: 150%;
            opacity: 0;
            transform:scale(0);
            pointer-events: none;
          }
        }
      }

    }
  }
}
