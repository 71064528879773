.profile {
  .profile-info {
    padding: $grid-gutter-width / 2 $grid-gutter-width;
    @include media-breakpoint-only(xs) {
      padding: $grid-gutter-width / 4 $grid-gutter-width / 2;
    }
  }
  .profile-name {
    position: relative;
    font-size: 1.3rem;
    margin: 0;
    > * {
      margin-top: 8px;
    }
    @include media-breakpoint-only(xs) {
    font-size: 1rem;
    }


    &.online,&.offline {
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: -16px;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        top: 50%;
        border:2px solid white;
        transform: translateY(-50%);
        background-color: $success;
        @include media-breakpoint-only(xs) {
          left: -10px;
        }
      }
    }
    &.offline {
      &:before {
        background-color: $gray-300;
      }
    }
    label {
      @include transition(all .2s ease);
      &:hover {
        cursor: pointer;
      }
    }
  }
  .profile-description {
    color: $gray-600
  }
}

.post {
  padding: $grid-gutter-width;
  //  border-bottom:1px solid $gray-200;
  .post-content {
    padding: $grid-gutter-width / 2;
    color: $gray-700;
    @include media-breakpoint-up(sm) {
      padding-left: 55px;
    }
  }
  &:not(:last-child) {
    padding-bottom: $grid-gutter-width / 4;
  }

}

.post-comments {
  .post-comment {
    &:first-child {
      margin-top: 20px;
    }
    margin: 5px 0 20px;
  }
  .comment-info {
    font-size: .75rem;
    .author {
      padding-left: 1em;
      font-weight: 600;

    }
    .date {
      float: right;
      color: $gray-500;
    }
  }
  .comment-content {
    font-size: .74rem;
    background-color: $gray-100;
    padding: $grid-gutter-width / 4 $grid-gutter-width / 2;
    @include border-radius();
  }
}

.post-actions {
  margin-top: $grid-gutter-width / 2;
}

.user-block {
  .avatar {
    float: left;
    width: 40px;
    height: 40px;
    margin-right: 1rem;
  }
  .username {
    display: block;
    font-size: 1rem;
    font-weight: 600;
    color: $blue;
  }
  .details {
    color: $gray-600;
  }
}

.friend {
  padding: $grid-gutter-width / 4;
}