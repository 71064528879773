body.sidebar {
  .wrap {
    @include media-breakpoint-up(md){
      margin-left: $sidebar-width;
    }

  }
}

.sidebar800 {
  z-index: $zindex-fixed;
  background-color: $gray-300;
  width: $sidebar-width;
  height: 100vh;
  padding-top: $nav800-height;
  position: absolute;
}