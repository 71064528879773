.tabs800 {
  background-color: $gray-100;
  border: none;
  justify-content: flex-start;
  padding-left: $border-radius-lg;
  .nav-item {
  }
  .nav-link {
    color: $gray-600;
    border-radius: 0;
    @extend %box-title;
    border: none;
    font-size: 1rem;
    background-color: $gray-100;
    padding: $grid-gutter-width / 8 $grid-gutter-width;

    &.active {
      color: $gray-700;
    }
  }
}

.box-tabs {
  .tabs800 {
    background-color: transparent;
    .nav-item {
      margin-bottom: 0;
    }
    .nav-item {
      box-shadow: none;

    }
    .nav-link {
      text-transform: uppercase;
      box-shadow: inset 0 -6px 15px -9px rgba(black,.2);
      margin-right: 10px;
      font-size: .7rem;
      font-weight: 600;
      border-top-left-radius: .5rem;
      border-top-right-radius: .5rem;
      &.active {
        box-shadow: none;
      }
    }
  }


}