/*Breadcrumbs*/

.breadcrumb {
  padding: 0;
  display: inline-flex;

  li {
    &:hover {
      text-decoration: none;
    }

    &:focus {
      z-index: 2;
      outline: 0;
      box-shadow: $pagination-focus-box-shadow;
    }

    a {
      color: $gray-800;

      &:hover {
        color: $pagination-hover-color;
        text-decoration: none;
      }

      &:after {
        content : '\f054';
        font-family: 'Font Awesome 5 Pro';
        padding: 5px;
        display: inline;
        font-size: 10px;
        display: inline;
      }

      // Opinionated: add "hand" cursor to non-disabled .page-link elements
      &:not([disabled]):not(.disabled) {
        cursor: pointer;
      }
    }
  }

  li {

    &.active {
      color: $gray-600;
    }

    &.disabled {
      a {
        color: $pagination-disabled-color;
        pointer-events: none;
        // Opinionated: remove the "hand" cursor set previously for .page-link
        cursor: auto;
      }
    }
  }
}