.video-details {
  padding: $grid-gutter-width;
}

.video800 {
  .video-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    font-size: 4rem;
    color: white;
    background-color: rgba(black, .3);
    display: block;
    width: 8rem;
    height: 8rem;
    text-indent: .5rem;
    text-align: center;
    line-height: 8rem;
    border-radius: 50%;
  }
  .video-title {
    font-size: 1rem;
    font-weight: 600;
  }

  .video-description {
    color: $gray-600;
  }

  .video-movie {
    overflow: hidden;
    position: relative;
  }
  .video-controls {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(black, .3);
    min-height: 35px;
    color: white;
    @include transition(all .3s ease);

  }
  .video-buttons {
    padding: 0 $grid-gutter-width / 2 0 $grid-gutter-width;
    font-size: 1.4rem;
    span {
      display: inline-block;
      @include transition(all .15s ease);
      &:hover {
        cursor: pointer;
        transform: scale(1.1);
      }
      &:not(:first-child) {
        margin-left: 2rem;
      }
    }

  }

  .video-views {
    text-align: right;
    span:first-child {
      font-size: .70rem;
      margin: 0;
      display: block;
      text-transform: uppercase;
    }
    span.count {
      color: $gray-600;
      font-size: 2rem;
      line-height: 2rem;
    }
  }
  .video-author {
    margin-top: 10px;
    text-align: right;
    span {
      &:first-child {
        font-size: .70rem;
        margin: 0;
        display: block;
        text-transform: uppercase;
      }
    }
  }

  .video-progress {
    @include transition(all .15s linear);
    margin-top: - $video-progress-height;
    display: block;
    height: $video-progress-height;
    background-color: $video-progress-color;
    position: relative;
    &:after {
      @include transition(all .15s linear);
      content: "";
      position: absolute;
      border-radius: 50%;
      display: block;
      height: $video-progress-height * 3;
      width: $video-progress-height * 3;
      background-color: $video-progress-color;
      right: 0;
      top: - $video-progress-height;
    }

    &:hover {
      margin-top: - $video-progress-height * 2;
      height: $video-progress-height * 2;
      &:after {
        height: $video-progress-height * 6;
        width: $video-progress-height * 6;
        top: - $video-progress-height * 2;
      }
    }
  }
}

.video-related-group {
  .video-related {
    margin-bottom: 1rem;
    &:hover {
      cursor: pointer;
    }
  }
  .preview {
    padding: 0 $grid-gutter-width / 2;
    position: relative;

    .duration {
      position: absolute;
      bottom: 5px;
      right: 10px;
      background-color: rgba(black, .4);
      color: white;
      padding: 0 6px;
    }
  }
  .details {
    font-size: .75rem;
    padding: 0 $grid-gutter-width / 2;
    color: $gray-600;
  }
}

.controls-on-hover {
  .video-controls {
    bottom: -70px;
  }
  .video-movie {
    &:hover {
      .video-controls {
        bottom: 0%;
      }
    }
  }
}
